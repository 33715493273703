// Import dependencies
import React from "react"
import ReactResizeDetector from "react-resize-detector"

import { Form } from "antd";
// Import components
import AccountTabs from "./accountTabs"
import AccountSettingsAccount from "./accountSettingsAccount"
import { PageContainer, ParagraphText } from "../../styled-components/UILibrary"
import ProfilTabPopup from "./profil-tab-popup";

import Layout from "../../layouts/layout";
import SEO from "../seo/seo";
import HeaderSeparator from "../separators/headerSeparator";
import CategoryDrawer from "../cateogry-drawer/categoty-drawer";
import SearchBar from "../search-bar/searchBar";

import ProfilTab from "./profil-tab";
import AnalyticsTab from "./analytics-tab";
import SettingsTab from "./settings-tab";
import LegalTab from "./legal-tab";
import ReferFriends from "./refer-friends";
import InputField from "../form/input/inputField"
import Select from "../form/select/select";
import {
  CreditPreference,
  CreditPreferenceInputs
} from "../../data/inputFields";
import CardsList from "../../components/index/cards-list";

// Import styles
import "./account-layout.css"
import OrderLayout from "./orderLayout"
import AccountSettingsContact from "./accountSettingsContact"
import { Link, navigate } from "gatsby";
// Import from Redux
import { connect } from "react-redux"
import { signOut } from "../../redux/actions/userAction"

// Import firebase
import { getFirebase } from "../../api/firebase"
import AccountSettingsBilling from "./accountSettingsBilling"
import { Constants } from "../../constants/Constants"
import Avatar from "../../assets/images/avatar.png"
import DeleteAccountIMG from "../../assets/images/delete_account.png"
import { generateOrderId } from "../../util/orderGenerator";

/*
    Account page in signed in state
*/
class AccountLayout extends React.Component {
  constructor(props) {
    super(props);
    this.updateCreditDetails = this.updateCreditDetails.bind(this);
    this.selectConvertCoins = this.selectConvertCoins.bind(this);
    this.handleUploadImg = this.handleUploadImg.bind(this);
    this.imgUrlPreview = this.imgUrlPreview.bind(this);
    this.getOrderHistory = this.getOrderHistory.bind(this);
    this.loadChallenges = this.loadChallenges.bind(this);
    this.convertPointsToCash = this.convertPointsToCash.bind(this);

    this.state = {
      isLoading: true,
      account_info: null,
      billing_info: null,
      contact_info: null,
      brandFollow: null,
      isMember: false,
      membershipStartDate: null,
      membershipEndDate: null,
      isSME: false,
      memberFrom: "",
      popupOpen: false,
      success: false,
      loadPopup: false,
      content: "list",
      returnSection: "list",
      analyticsData: [],
      dataLoad: false,
      mostBrand: "",
      UserCreditPreference: "",
      creditDetails: {},
      UserCreditPreferenceEdit: "",
      creditDetailsEdit: {},
      streetEdit: "",
      postalCodeEdit: "",
      contactNumberEdit: "",
      userCash: 0,
      userCashMemory: 0,
      userCoins: 0,
      userCoinsMemory: 0,
      convertCoinsSelect: -1,
      converCoinsOption: [5, 10, 25, 50],
      profilImgUpdate: null,
      profilImgUpdateUrl: null,
      profil_img: Avatar,
      referencePayment: "",
      orderHistory: [],
      redeemedChallenges: [],
      convertHistory: [],
      imgSizeError: "",
      storeHistory: []
    }
  }

  loadChallenges = () => {
    const app = import("firebase/app");
    const firestore = import("firebase/firestore");
    let challenges = [];
    Promise.all([app, firestore])
      .then(([firebase]) =>
        getFirebase(firebase)
          .firestore()
          .collection("challenges")
          .where("users", "array-contains", this.props.userToken.uid)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              challenges.push(doc.data());
            });
          })
          .then(() => {
            this.setState({ redeemedChallenges: challenges });
          })
      )
  }

  editProfil = (profilParams) => {
    this.setState({ loadPopup: true });

    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.userToken.uid)
        .update(
          {
            "contact_info.home_address": profilParams.home_address.trim(),
            "contact_info.postal_code": profilParams.postal_code.trim(),
            "account_info.phone": profilParams.contact_number.trim()
          }
        ).then(() => {
          this.setState({
            success: true,
            loadPopup: false,
            "contact_info.home_address": profilParams.home_address.trim(),
            "contact_info.postal_code": profilParams.postal_code.trim(),
            "account_info.phone": profilParams.contact_number.trim(),
          });
        })
    })


  }

  // Updates the details of credit preference
  updateCreditDetails = (item, eventOrValue, asValue = false) => {
    let temp = this.state.creditDetails;
    const key = item;
    temp[key] = asValue ? eventOrValue : eventOrValue.target.value;

    this.setState({ creditDetailsEdit: temp });
  };

  selectConvertCoins = (option) => {
    if (option == 0 && this.state.userCoins >= 500) this.setState({ convertCoinsSelect: 0 })
    if (option == 1 && this.state.userCoins >= 100) this.setState({ convertCoinsSelect: 1 })
    if (option == 2 && this.state.userCoins >= 2500) this.setState({ convertCoinsSelect: 2 })
    if (option == 3 && this.state.userCoins >= 5000) this.setState({ convertCoinsSelect: 3 })
  }

  togglePopup = () => {
    this.setState({ popupOpen: !this.state.popupOpen, success: false });
  }

  imgUrlPreview = () => {
    const file = this.state.profilImgUpdate;
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = (e) => {
        resolve(e.target.result)
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  handleUploadImg = async (e) => {
    if (e.target.files[0].size <= 800000) {
      this.setState({ imgSizeError: "" })
      this.setState({ profilImgUpdate: e.target.files[0] }, async function () {
        const result = await this.imgUrlPreview();
        this.setState({ profilImgUpdateUrl: result })
      });
    } else {
      console.log("size too big")
      this.setState({ imgSizeError: "Oops! Your image is larger than 800 KB." })
    }
  }

  signOut = () => {
    this.setState({ isLoading: true })
    const app = import("firebase/app")
    const auth = import("firebase/auth")

    Promise.all([app, auth]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.setState({ isLoading: false })
          this.props.dispatch(signOut())
          navigate("/login");
        })
        .catch()
    })
  }

  getOrderHistory = () => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")
    let orderHistoryList = [];
    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore().collection("payments").where("user_token", "==", this.props.userToken.uid).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            orderHistoryList.push({ date: doc.data().dateFull, reference: doc.data().reference });
          });
        }).then(() => {
          this.setState({ orderHistory: orderHistoryList });
        });;
    });
  }

  withdrawFunds = () => {
    if (this.state.userCash > 0 && this.state.creditDetails != {}) {
      let date = new Date();
      const dd = date.getDate();
      const mm = date.getMonth();
      const arrayMonths = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const arrayMonthsShort = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      const yyyy = date.getFullYear();
      let todayShort = dd + '/' + arrayMonthsShort[mm] + '/' + yyyy;
      let today = dd + ' ' + arrayMonths[mm] + ' ' + yyyy;

      const dueDate = new Date(yyyy, mm, dd + 1);
      const ddDueteDate = dueDate.getDate();
      const mmDueteDate = dueDate.getMonth();
      const yyyyDueteDate = dueDate.getFullYear();
      const dueDateShort = ddDueteDate + '/' + arrayMonthsShort[mmDueteDate] + '/' + yyyyDueteDate;

      var hours = date.getHours();
      var minutes = date.getMinutes();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const referencePayment = generateOrderId();
      const app = import("firebase/app")
      const firestore = import("firebase/firestore")
      Promise.all([app, firestore]).then(([firebase]) => {

        getFirebase(firebase)
          .firestore()
          .collection("payments")
          .doc()
          .set({
            firstName: this.state.account_info.first_name,
            lastName: this.state.account_info.last_name,
            total: this.state.userCash,
            reference: referencePayment,
            isMember: this.state.isMember,
            reqDate: todayShort,
            paid_status: false,
            dueDate: dueDateShort,
            dateCode: yyyy + " " + mm + " " + dd + " " + hours + " " + minutes,
            email: this.state.account_info.email,
            transferMethod: this.state.UserCreditPreference,
            transferMethodDetails: this.state.creditDetails,
            user_token: this.props.userToken.uid,
            dateFull: today
          })
          .then(() => {
            getFirebase(firebase)
              .firestore().collection("users").doc(this.props.userToken.uid).update({
                cash: 0
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
            this.setState({ content: "withdraw_success", returnSection: "list", userCash: 0, referencePayment: referencePayment });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

      })
    }
  }

  convertPointsToCash = () => {

    const app = import("firebase/app");
    const firestore = import("firebase/firestore");

    const newUserCash = this.state.userCash + this.state.converCoinsOption[this.state.convertCoinsSelect];
    const newUserCoins = this.state.userCoins - this.state.converCoinsOption[this.state.convertCoinsSelect] * 100;

    Promise.all([app, firestore]).then(([firebase]) => {

      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.userToken.uid)
        .update({
          cash: newUserCash,
          coin: newUserCoins
        })
        .then(() => {
          let date = new Date();
          const dd = date.getDate();
          const mm = date.getMonth();
          const arrayMonths = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
          const yyyy = date.getFullYear();
          let today = dd + ' ' + arrayMonths[mm] + ' ' + yyyy;
          getFirebase(firebase)
            .firestore()
            .collection("convert")
            .doc()
            .set({
              convertId: generateOrderId(),
              userToken: this.props.userToken.uid,
              amount: this.state.converCoinsOption[this.state.convertCoinsSelect],
              date: today
            })
            .then(() => this.setState({ content: "convert_success", returnSection: "list", userCoinsMemory: newUserCoins, userCashMemory: newUserCash, convertCoinsSelect: -1 }))
        }
        )
        .catch((error) => this.setState({ content: "convert_success", returnSection: "list" }));

    });

  }

  showConvertHistory = () => {
    this.setState({ content: "convert_history", returnSection: "coin_balance" });
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")
    let convertHistoryList = [];
    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore().collection("convert").where("userToken", "==", this.props.userToken.uid).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            convertHistoryList.push({
              convertId: doc.data().convertId,
              amount: doc.data().amount,
              date: doc.data().date
            });
          });
        }).then(() => {
          this.setState({ convertHistory: convertHistoryList });
        });;
    });

  }

  showStoreHistory = () => {
    this.setState({ content: "store_history" });
    const app = import("firebase/app");
    const firestore = import("firebase/firestore");
    let storeHistoryList = [];
  
    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("store_order")
        .where("userId", "==", this.props.userToken.uid)
        .where("status", "==", true)
        .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            storeHistoryList.push({
              id: doc.id, // Use document ID as id
              total: doc.data().total_quote,
              date: this.formatDate(doc.data().deliveryDate) // Format the date
            });
          });
        }).then(() => {
          this.setState({ storeHistory: storeHistoryList });
        });
    });
  }
  
  // Function to format the date
  formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  

  componentDidMount() {
    this.setState({ isLoading: true })
    // Fetch user data
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.userToken.uid)
        .onSnapshot(doc => {
          // Get data and store in state
          let data = doc.data()
          this.setState({
            account_info: data.account_info,
            billing_info: data.billing_info,
            contact_info: data.contact_info,
            profil_img: data.account_info.profil_img ? data.account_info.profil_img : Avatar,
            userCash: data.cash,
            userCoins: data.coin,
            UserCreditPreference: data.transfer_info.UserCreditPreference,
            creditDetails: data.transfer_info.creditDetails,
            streetEdit: data.contact_info.home_address,
            postalCodeEdit: data.contact_info.postal_code,
            contactNumberEdit: data.account_info.phone,
            UserCreditPreferenceEdit: data.transfer_info.UserCreditPreference,
            brandFollow: data.brandFollow,
            premium_orders: data.premium_orders,
            general_orders: data.general_orders,
            memberFrom: data.membership_info.start_date,
            membershipStartDate: data.membership_info.start_date,
            membershipEndDate: data.membership_info.end_date,
            isMember: data.membership_info.is_member,
            isSME: data.membership_info.is_SME ? data.membership_info.is_SME : null,
            membershipID: data.membership_info.stripe_subscription_id,
            isLoading: false,
            membership_info: data.membership_info,
            isLoading: false
          }, function () {
            Promise.all([app, firestore]).then(([firebase]) => {
              getFirebase(firebase)
                .firestore()
                .collection("orders")
                .where("email", "==", this.state.account_info.email)
                .get()
                .then(querySnapshot => {
                  let dataArray = [];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    dataArray.push(doc.data())
                  });
                  this.setState({ analyticsData: dataArray, dataLoad: true }, function () {
                    let brandsArray = [];
                    [...this.state.analyticsData, ...this.state.analyticsData]
                      .map(product => {
                        let itemsList = product.items ? Object.keys(product.items).map((key) => product.items[key]) : [];
                        let generalItemsList = product.generalItems ? Object.keys(product.generalItems).map((key) => product.generalItems[key]) : [];
                        return [...itemsList, ...generalItemsList]
                      })
                      .forEach(item => { item.forEach(subItem => { brandsArray.push(subItem.brand) }) })
                    brandsArray = brandsArray.filter(brand => brand != "N/A");

                    let mostRecycledBrand = brandsArray.sort((a, b) =>
                      brandsArray.filter(v => v === a).length
                      - brandsArray.filter(v => v === b).length
                    ).pop();
                    this.setState({ mostBrand: mostRecycledBrand });

                  })
                })
            });
          })
        })
    });




  }

  render() {
    const { form } = this.props;

    if (!this.state.isLoading) {
      let membershipStartDate = "";
      let membershipEndDate = "";

      if (this.state.membershipStartDate && this.state.membershipEndDate) {
        membershipStartDate = this.state.membershipStartDate.split(' ');
        membershipStartDate.forEach((number, index) => {
          if (number.length == 1) {
            membershipStartDate[index] = "0" + number;
          }
        });
        let msd = membershipStartDate;

        membershipEndDate = this.state.membershipEndDate.split(' ');
        membershipEndDate.forEach((number, index) => {
          if (number.length == 1) {
            membershipEndDate[index] = "0" + number;
          }
        });
        let med = membershipEndDate;


        let dateStart = new Date(msd[0], msd[1], msd[2], msd[3], msd[4]);
        const ddStart = dateStart.getDate();
        const mmStart = dateStart.getMonth();
        const yyyyStart = dateStart.getFullYear();

        let dateEnd = new Date(med[0], med[1], med[2], med[3], med[4]);
        const ddEnd = dateEnd.getDate();
        const mmEnd = dateEnd.getMonth();
        const yyyyEnd = dateEnd.getFullYear();

        const arrayMonths = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];

        membershipStartDate = ddStart + ' ' + arrayMonths[mmStart] + ' ' + yyyyStart;
        membershipEndDate = ddEnd + ' ' + arrayMonths[mmEnd] + ' ' + yyyyEnd;
      }

      return (
        <div className="profil_account_container">

          {this.state.content != "list" &&
            <div className="header_section_return">
              <div onClick={() => {

                if (this.state.content == "edit_transfer") {
                  this.setState({
                    UserCreditPreferenceEdit: this.state.UserCreditPreference,
                    creditDetailsEdit: {}
                  });
                }

                if (this.state.content == "edit_profil") {
                  this.setState({
                    profilImgUpdate: null,
                    profilImgUpdateUrl: null,
                  });
                }


                this.setState({ content: this.state.returnSection, returnSection: "list" });
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.0005 7.0007H3.83047L8.71047 2.1207C9.10047 1.7307 9.10047 1.0907 8.71047 0.700703C8.32047 0.310703 7.69047 0.310703 7.30047 0.700703L0.710469 7.2907C0.320469 7.6807 0.320469 8.3107 0.710469 8.7007L7.30047 15.2907C7.69047 15.6807 8.32047 15.6807 8.71047 15.2907C9.10047 14.9007 9.10047 14.2707 8.71047 13.8807L3.83047 9.0007H15.0005C15.5505 9.0007 16.0005 8.5507 16.0005 8.0007C16.0005 7.4507 15.5505 7.0007 15.0005 7.0007Z" fill="black" />
                </svg>
                {this.state.content == "cash_balance" && <span>Cash balance</span>}
                {this.state.content == "coin_balance" && <span>Coin balance</span>}
                {this.state.content == "refer_friends" && <span>Refer friends</span>}
                {this.state.content == "profile" && <span>Profile</span>}
                {this.state.content == "membership" && <span>Membership</span>}
                {this.state.content == "following" && <span>Following</span>}
                {this.state.content == "recycling_history" && <span>Recycling history</span>}
                {this.state.content == "store_history" && <span>Ree Store history</span>}
                {this.state.content == "my_rewards" && <span>My rewards</span>}
                {this.state.content == "settings" && <span>Settings</span>}

                {this.state.content == "edit_profil" && <span>Edit profile</span>}
                {this.state.content == "save_profil" && <span>Account</span>}

                {this.state.content == "delete_account" && <span>Delete my account</span>}
                {this.state.content == "account_deleted" && <span>Account</span>}

                {this.state.content == "cancel_membership" && <span>Cancel membership</span>}
                {this.state.content == "membership_canceled" && <span>Account</span>}

                {this.state.content == "withdraw_funds" && <span>Withdraw funds</span>}
                {this.state.content == "withdraw_success" && <span>Account</span>}

                {this.state.content == "convert_history" && <span>View conversion history</span>}
                {this.state.content == "order_history" && <span>View order history</span>}

                {this.state.content == "edit_transfer" && <span>Edit transfer details</span>}
                {this.state.content == "transfer_success" && <span>Account</span>}

                {this.state.content == "convert_coins" && <span>Convert coins</span>}
                {this.state.content == "convert_coins_summary" && <span>Conversion</span>}

              </div>
            </div>
          }
          {this.state.content == "list" &&
            <div className="profil_container">
              <div className="profil_head">
                <div className="profil_avatar" style={{ backgroundImage: "url('" + this.state.profil_img + "')" }}></div>
                <div className="profil_head_flex">
                  <h1>Welcome back, {this.state.account_info.first_name} 👋</h1>
                  <span>{this.state.isMember ? "Hero user account" : "Free user account"}</span>
                </div>
              </div>
              <h1 className="profil_section_title">Payments</h1>
              <div className="profil_section_item" onClick={() => this.setState({ content: "cash_balance", userCashMemory: this.state.userCash })}>
                <span>Cash balance</span>
                <span className="badge">${this.state.userCash}</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item" onClick={() => {
                this.setState({ content: "coin_balance", userCoinsMemory: this.state.userCoins });
              }}>
                <span>Coin balance</span>
                <span className="badge">{this.state.userCoins}</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item" onClick={() => this.setState({ content: "refer_friends" })}>
                <span>Refer friends (Earn $100)</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <h1 className="profil_section_title">Your account</h1>
              <div className="profil_section_item" onClick={() => this.setState({ content: "profile" })}>
                <span>Edit profile</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item"
                onClick={() =>
                  this.state.isMember
                    ? this.setState({ content: "membership" })
                    : navigate("/pricing")
                }
              >
                <span>Membership</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item" onClick={() => this.setState({ content: "following" })}>
                <span>Following</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <h1 className="profil_section_title">Activity</h1>
              <div className="profil_section_item" onClick={() => this.setState({ content: "recycling_history" })}>
                <span>Recycling history</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item" onClick={() => this.showStoreHistory()}>
                <span>Ree Store history</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <div className="profil_section_item" onClick={() => { this.loadChallenges(); this.setState({ content: "my_rewards" }) }}>
                <span>My rewards</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <h1 className="profil_section_title">Account settings</h1>
              <div className="profil_section_item" onClick={() => this.setState({ content: "settings" })}>
                <span>Settings</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
              <a href="https://help.reinin.sg/" className="profil_section_item">
                <span>Help center</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </a>
              <div>
                <div className="logout_btn" onClick={() => { this.signOut() }}>Log out</div>
              </div>
            </div>
          }
          {this.state.content == "profile" &&
            <div className="profil_container_form edit_profil_form_mobile">
              <div className="profil_head">
                <div className="profil_avatar" style={{ backgroundImage: "url('" + this.state.profil_img + "')" }}></div>
              </div>
              <div className="edit_profil_form">
                <div className="profil_container_for_desktop">
                  <InputField placeholder="Name" noForm disabled value={this.state.account_info.first_name + " " + this.state.account_info.last_name} />
                  <InputField placeholder="Email" noForm disabled value={this.state.account_info.email} />
                  <InputField placeholder="Contact Number" noForm disabled value={this.state.account_info.phone} />
                  <InputField placeholder="Addresss" noForm disabled value={this.state.contact_info.home_address} />
                  <InputField placeholder="Postal Code" noForm disabled value={this.state.contact_info.postal_code} />
                </div>

                <div className="profil_container_for_mobile">
                  <div class="recycling_history_item">{this.state.account_info.first_name + " " + this.state.account_info.last_name}</div>
                  <div class="recycling_history_item_title">Contact</div>
                  <div class="recycling_history_item">Email<span>{this.state.account_info.email}</span></div>
                  <div class="recycling_history_item">Contact Number<span>{this.state.account_info.phone}</span></div>
                  <div class="recycling_history_item">Address<span>{this.state.contact_info.home_address}</span></div>
                  <div class="recycling_history_item">Postal Code<span>{this.state.contact_info.postal_code}</span></div>
                </div>
                <div className="btn_edit_profil" onClick={() => this.setState({ content: "edit_profil", returnSection: "profile", profilImgUpdateUrl: this.state.profil_img })}>Edit</div>
              </div>


            </div>
          }
          {this.state.content == "edit_profil" &&
            <div className="profil_container_form">
              <div className="profil_head">
                <div className="profil_head_edit_flex">
                  <div className="profil_avatar" style={{ backgroundImage: "url('" + this.state.profilImgUpdateUrl + "')" }}></div></div>
                <div className="profil_head_edit_avatar">
                  <label for="input_img" className="edit_avatar_btn">Choose File</label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="input_img"
                    style={{ display: "none" }}
                    onChange={this.handleUploadImg}
                  />
                  <span>JPG or PNG. Max size of 800KB.</span>
                  {this.state.imgSizeError && <span className="edit_avatar_error">{this.state.imgSizeError}</span>}
                </div>
              </div>
              <div className="edit_profil_form">
                <InputField placeholder="Street / Blk. / Unit No." noForm
                  onChange={e => this.setState({ streetEdit: e.target.value })}
                  value={this.state.streetEdit ? this.state.streetEdit : ""}
                />
                <InputField placeholder="Postal Code" noForm
                  onChange={e => this.setState({ postalCodeEdit: e.target.value })}
                  value={this.state.postalCodeEdit ? this.state.postalCodeEdit : ""}
                />
                <InputField placeholder="Contact Number" noForm
                  onChange={e => this.setState({ contactNumberEdit: e.target.value })}
                  value={this.state.contactNumberEdit ? this.state.contactNumberEdit : ""}
                />
                <div className="btn_edit_profil" onClick={() => {

                  const app = import("firebase/app");
                  const firestore = import("firebase/firestore");


                  if (this.state.profilImgUpdate) {
                    const file = this.state.profilImgUpdate;
                    const storage = import("firebase/storage");
                    Promise.all([app, firestore, storage]).then(([firebase]) => {
                      var storageRef = getFirebase(firebase).storage().ref();
                      var imagesRef = storageRef.child('profile/' + this.props.userToken.uid);
                      var uploadTask = imagesRef.put(file);
                      uploadTask.on('state_changed',
                        (snapshot) => {
                          // Handle loading
                          console.log("loading");
                        },
                        (error) => {
                          // Handle unsuccessful uploads
                          console.log(error);
                        },
                        () => {
                          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            getFirebase(firebase)
                              .firestore()
                              .collection("users")
                              .doc(this.props.userToken.uid)
                              .update({
                                "account_info.profil_img": downloadURL,
                                "contact_info.home_address": this.state.streetEdit,
                                "contact_info.postal_code": this.state.postalCodeEdit,
                                "account_info.phone": this.state.contactNumberEdit
                              })
                              .then(() => this.setState({ content: "save_profil", returnSection: "list", profilImgUpdate: null, profilImgUpdateUrl: null }))
                              .catch((error) => this.setState({ content: "save_profil", returnSection: "list", profilImgUpdate: null, profilImgUpdateUrl: null }));
                          });
                        }
                      );

                    });
                  } else {
                    Promise.all([app, firestore]).then(([firebase]) => {

                      getFirebase(firebase)
                        .firestore()
                        .collection("users")
                        .doc(this.props.userToken.uid)
                        .update({
                          "contact_info.home_address": this.state.streetEdit,
                          "contact_info.postal_code": this.state.postalCodeEdit,
                          "account_info.phone": this.state.contactNumberEdit,
                        })
                        .then(() => this.setState({ content: "save_profil", returnSection: "list" }))
                        .catch((error) => this.setState({ content: "save_profil", returnSection: "list" }));

                    });

                  }


                }}>Save</div>
              </div>
            </div>
          }
          {this.state.content == "save_profil" &&
            <div className="save_profil_success">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>You’re up to date</h1>
            </div>
          }
          {this.state.content == "refer_friends" &&
            <ReferFriends
              email={this.state.account_info.email}
              firstName={this.state.account_info.first_name}
            />
          }
          {this.state.content == "following" &&
            <div className="following_container">
              <h1>Brands you’re following</h1>
              <p>You’ll get notified by email when we are buying-back new models or new product lines from the brands you follow. You can follow a brand on its dedicated page.</p>
              {
                this.state.brandFollow
                  .map(brand =>
                    <div className="following_item">
                      <span>{brand}</span>
                      <span className="following_item_unfollow" onClick={() => {

                        const app = import("firebase/app");
                        const firestore = import("firebase/firestore");

                        Promise.all([app, firestore]).then(([firebase]) => {

                          getFirebase(firebase)
                            .firestore()
                            .collection("users")
                            .doc(this.props.userToken.uid)
                            .update({
                              brandFollow: firebase.firestore.FieldValue.arrayRemove(brand)
                            })
                            .then(() => console.log("Follow done"))
                            .catch((error) => console.log("Follow NOT DONE"));

                        });

                      }}>Unfollow</span>
                    </div>
                  )
              }
            </div>
          }
          {this.state.content == "settings" &&
            <div className="settings_container">
              <div className="profil_section_item" onClick={() => this.setState({ content: "delete_account", returnSection: "settings" })}>
                <span>Delete my account</span>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" />
                </svg>
              </div>
            </div>
          }
          {this.state.content == "delete_account" &&
            <div className="delete_account_container">
              <img src={DeleteAccountIMG} />
              <span>Are you sure?</span>
              <div className="delete_account_btn" onClick={() => this.setState({ content: "account_deleted", returnSection: "list" })}>Yes, delete my account</div>
            </div>
          }
          {this.state.content == "account_deleted" &&
            <div className="save_profil_success">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>Account deleted</h1>
            </div>
          }
          {this.state.content == "recycling_history" &&
            <div className="recycling_history_container">
              <h1>Analytics</h1>
              <div className="recycling_history_item">Member since<span>{this.state.account_info["sign_date"]}</span></div>
              <div className="recycling_history_item">Last recycled<span>{this.state.analyticsData && this.state.analyticsData.length && this.state.dataLoad ? this.state.analyticsData.sort((a, b) => {

                let dateCodeA = a.dateCode.split(' ');
                dateCodeA.forEach((number, index) => {
                  if (number.length == 1) {
                    dateCodeA[index] = "0" + number;
                  }
                });
                let dateCodeB = b.dateCode.split(' ');
                dateCodeB.forEach((number, index) => {
                  if (number.length == 1) {
                    dateCodeB[index] = "0" + number;
                  }
                });

                dateCodeA = parseInt(dateCodeA.join(""));
                dateCodeB = parseInt(dateCodeB.join(""));

                return dateCodeA - dateCodeB
              })[0]["dateFull"] : "-"
              }</span></div>
              <div className="recycling_history_item">Times recycled<span>{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.length : 0}</span></div>
              <div className="recycling_history_item">Recycling frequency<span>{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.length : 0}x/month</span></div>
              <div className="recycling_history_item">Total earned (approx.)<span>${this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + b.order_total, 0) : 0}</span></div>
              <div className="recycling_history_item">Total recycled (Kg)<span>{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (b.totalWeight), 0) : 0} Kg</span></div>
              <div className="recycling_history_item">Total recycled (items)<span>{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (b.totalItems), 0) : 0}</span></div>
              <div className="recycling_history_item">Your most recycled brand<span>{this.state.mostBrand ? this.state.mostBrand : "-"}</span></div>
              <h1>Premium analytics<span>COMING SOON</span></h1>
            </div>
          }
          {
            this.state.content == "store_history" &&
            <div className="order_history_container convert_history_container">
              {
                this.state.storeHistory.length ?
                  <div className="order_history_header">
                    <span>Order number</span>
                    <span>Order Total</span>
                    <span>Order date</span>
                    </div> : ""
              }
              {
                this.state.storeHistory.map(convert => <div className="recycling_history_item">
                  <span className="recycling_history_id">{convert.id}</span>
                  <span className="recycling_history_amount">${convert.total}</span>
                  <span className="recycling_history_date">{convert.date}</span>
                </div>)
              }
            </div>
          }
          {this.state.content == "membership" &&
            <div className="membership_container">
              <div className="membership_card">
                {
                  this.state.isMember ? this.state.isSME ? "Hero SME (Paid)" : "Hero (Paid)" : "Guardian (Free)"
                }
              </div>
              <div className="recycling_history_item">Member since<span>{membershipStartDate}</span></div>
              <div className="recycling_history_item">Next billing date<span>{membershipEndDate}</span></div>
              <div className="recycling_history_item recycling_history_click">Update billing details<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
              <a href="https://help.reinin.sg/topics/basic-startup/" target="_blank" className="recycling_history_item recycling_history_click">Membership FAQs<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></a>
              <div className="recycling_history_item recycling_history_click recycling_history_item_red" onClick={() => this.setState({ content: "cancel_membership", returnSection: "membership" })}>Cancel membership<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
            </div>
          }
          {this.state.content == "cancel_membership" &&
            <div className="delete_account_container">
              <img src={DeleteAccountIMG} />
              <span>Are you sure?</span>
              <div className="delete_account_btn" onClick={() => this.setState({ content: "membership_canceled", returnSection: "list" })}>Yes, cancel my membership</div>
            </div>
          }
          {this.state.content == "membership_canceled" &&
            <div className="save_profil_success">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>Membership cancelled</h1>
            </div>
          }
          {this.state.content == "cash_balance" &&
            <div className="balance_container">
              <div className="balance_card">
                You have
                <span>${this.state.userCashMemory}</span>
              </div>
              <div className="balance_item" onClick={() => this.setState({ content: "withdraw_funds", returnSection: "cash_balance" })}>Withdraw funds<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
              <div className="balance_item" onClick={() => { this.getOrderHistory(); this.setState({ content: "order_history", returnSection: "cash_balance" }) }}>View order history<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
              <Link to="/"><div className="balance_item">Recycle more<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div></Link>
              <a href="https://help.reinin.sg/topics/account-bill/" className="balance_item">Payment FAQs<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></a>
            </div>
          }
          {this.state.content == "withdraw_funds" &&
            <div className="withdraw_funds_container">
              <div className="balance_card">
                You have
                <span>${this.state.userCashMemory}</span>
              </div>
              <h1>Transfer details</h1>
              <div className="profil_container_for_desktop">

                <InputField disabled placeholder="Transfer Method" noForm value={this.state.UserCreditPreference} />

                {CreditPreferenceInputs[this.state.UserCreditPreferenceEdit]
                  ? CreditPreferenceInputs[this.state.UserCreditPreferenceEdit].map(
                    (item, i) => (
                      <InputField
                        key={item.placeholder.split(" ").join("_").toLowerCase()}
                        id={item.placeholder}
                        type={item.type}
                        noForm
                        disabled
                        placeholder={item.placeholder}
                        value={this.state.creditDetails[item.placeholder]}
                      />
                    )
                  )
                  : null}

              </div>
              <div className="profil_container_for_mobile">
                <div class="recycling_history_item">Transfer method<span>{this.state.UserCreditPreference}</span></div>
                {CreditPreferenceInputs[this.state.UserCreditPreferenceEdit]
                  ? CreditPreferenceInputs[this.state.UserCreditPreferenceEdit].map(
                    (item, i) => (
                      <div class="recycling_history_item">{item.placeholder}<span>{this.state.creditDetails[item.placeholder]}</span></div>
                    )
                  )
                  : null}
              </div>
              <div className="withdraw_details_edit_link_container">
                <span className="withdraw_details_edit_link" onClick={() => this.setState({ content: "edit_transfer", returnSection: "withdraw_funds" })}>Edit transfer details</span>
              </div>
              <h1>You will receive<span>${this.state.userCashMemory}</span></h1>
              <div className="btn_edit_profil" onClick={() => this.withdrawFunds()}>Withdraw Funds</div>
            </div>
          }
          {this.state.content == "withdraw_success" &&
            <div className="save_profil_success">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>Transfer on the way!</h1>
              <span>Reference {this.state.referencePayment}</span>
              <div className="withdraw_check_faq">
                <h1>When will I receive the funds?</h1>
                <p>Please allow up to 24-hours for Pay Now, PayPal, and bank transfers to be processed</p>
                <a href="https://help.reinin.sg/topics/account-bill/"><div className="withdraw_check_faq_btn">Payment FAQs</div></a>
              </div>
            </div>
          }
          {this.state.content == "order_history" &&
            <div className="order_history_container">
              {
                this.state.orderHistory.length ?
                  <div className="order_history_header">
                    <span>Order number</span>
                    <span>Order date</span>
                  </div> : ""
              }
              {
                this.state.orderHistory.map(order => <div className="recycling_history_item">{order.reference}<span className="recycling_history_item_span">{order.date}</span></div>)
              }
            </div>
          }
          {this.state.content == "convert_history" &&
            <div className="order_history_container convert_history_container">
              {
                this.state.convertHistory.length ?
                  <div className="order_history_header">
                    <span>Convert number</span>
                    <span>Convert amount</span>
                    <span>Convert date</span>
                  </div> : ""
              }
              {
                this.state.convertHistory.map(convert => <div className="recycling_history_item">
                  <span className="recycling_history_id">{convert.convertId}</span>
                  <span className="recycling_history_amount">{convert.amount} $</span>
                  <span className="recycling_history_date">{convert.date}</span>
                </div>)
              }
            </div>
          }
          {this.state.content == "edit_transfer" &&
            <div className="edit_transfer_container">

              <div className="checkout_payment_container">
                <Select
                  isRequired
                  id="credit-options"
                  label="How We’ll Pay You"
                  placeholder={this.state.UserCreditPreferenceEdit}
                  error="Please select credit options."
                  form={form}
                  data={CreditPreference}
                  onChange={value => this.setState({ UserCreditPreferenceEdit: value })}
                  whiteLabel
                />
              </div>

              {/* Extra input fields depending on Select value */}
              {CreditPreferenceInputs[this.state.UserCreditPreferenceEdit]
                ? CreditPreferenceInputs[this.state.UserCreditPreferenceEdit].map(
                  (item, i) => (
                    Array.isArray(item.options)
                      ? <Select
                        isRequired
                        id={item.placeholder}
                        label={item.placeholder}
                        placeholder={item.placeholder}
                        error={item.error}
                        form={form}
                        data={item.options}
                        onChange={value =>
                          this.updateCreditDetails(item.placeholder, value, true)
                        }
                        whiteLabel
                      />
                      : <InputField
                        key={item.placeholder.split(" ").join("_").toLowerCase()}
                        isRequired
                        id={item.placeholder}
                        error={item.error}
                        type={item.type}
                        form={form}
                        placeholder={item.placeholder}
                        onChange={e =>
                          this.updateCreditDetails(item.placeholder, e)
                        }
                        phoneNumber={item.phoneNumber}
                      />
                  )
                )
                : null}
              <div className="btn_edit_profil" onClick={() => {

                let creditDetailsFinal = {};
                CreditPreferenceInputs[this.state.UserCreditPreferenceEdit].map(item => {
                  creditDetailsFinal[item.placeholder] = this.state.creditDetailsEdit[item.placeholder] ? this.state.creditDetailsEdit[item.placeholder] : "";
                })

                const app = import("firebase/app");
                const firestore = import("firebase/firestore");

                Promise.all([app, firestore]).then(([firebase]) => {
                  getFirebase(firebase)
                    .firestore()
                    .collection("users")
                    .doc(this.props.userToken.uid)
                    .update({
                      transfer_info: {
                        UserCreditPreference: this.state.UserCreditPreferenceEdit,
                        creditDetails: creditDetailsFinal
                      }
                    })
                    .then(() => this.setState({
                      content: "transfer_success",
                      returnSection: "withdraw_funds",
                      creditDetails: this.state.UserCreditPreferenceEdit,
                      creditDetails: creditDetailsFinal
                    }))
                    .catch((error) => this.setState({ content: "transfer_success", returnSection: "withdraw_funds" }));
                });

              }}>Save</div>
            </div>
          }
          {this.state.content == "transfer_success" &&
            <div className="save_profil_success transfer_success_container">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>You’re up to date</h1>
            </div>
          }
          {this.state.content == "coin_balance" &&
            <div className="balance_container coin_balance_container">
              <div className="balance_card">
                You have
                <span>{this.state.userCoinsMemory}<span>coins</span></span>
              </div>
              <div className="balance_item" onClick={() => this.setState({ content: "convert_coins", returnSection: "coin_balance" })}>Convert coins to cash<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
              <div className="balance_item" onClick={() => this.showConvertHistory()}>View conversion history<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div>
              <Link to="/"><div className="balance_item">Recycle more<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></div></Link>
              <a href="https://help.reinin.sg/topics/reinin-coins-and-rewards/" className="balance_item">Coins FAQs<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#BDBDBD" /> </svg></a>
            </div>
          }
          {this.state.content == "convert_coins" &&
            <div className="withdraw_funds_container convert_coins_container">
              <div className="balance_card">
                You have
                <span>{this.state.userCoinsMemory}<span>coins</span></span>
              </div>
              <h1>Convert Reinin Coins to Cash</h1>
              <div onClick={() => this.selectConvertCoins(0)} className={this.state.userCoins >= 500 ? this.state.convertCoinsSelect == 0 ? "convert_coins_items selected" : "convert_coins_items" : "convert_coins_items disabled"}>
                Gets $5<span>500 coins</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#0AAE67" />
                </svg>
              </div>
              <div onClick={() => this.selectConvertCoins(1)} className={this.state.userCoins >= 1000 ? this.state.convertCoinsSelect == 1 ? "convert_coins_items selected" : "convert_coins_items" : "convert_coins_items disabled"}>
                Gets $10<span>1000 coins</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#0AAE67" />
                </svg>
              </div>
              <div onClick={() => this.selectConvertCoins(2)} className={this.state.userCoins >= 2500 ? this.state.convertCoinsSelect == 2 ? "convert_coins_items selected" : "convert_coins_items" : "convert_coins_items disabled"}>
                Gets $25<span>2500 coins</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#0AAE67" />
                </svg>
              </div>
              <div onClick={() => this.selectConvertCoins(3)} className={this.state.userCoins >= 5000 ? this.state.convertCoinsSelect == 3 ? "convert_coins_items selected" : "convert_coins_items" : "convert_coins_items disabled"}>
                Gets $50<span>5000 coins</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#0AAE67" />
                </svg>
              </div>
              <div className="btn_edit_profil" onClick={() => { if (this.state.convertCoinsSelect != -1) this.setState({ content: "convert_coins_summary", returnSection: "convert_coins" }) }}>Next</div>
            </div>
          }
          {this.state.content == "convert_coins_summary" &&
            <div className="withdraw_funds_container convert_coins_container">
              <div className="balance_card">
                You have
                <span>{this.state.userCoinsMemory}<span>coins</span></span>
              </div>
              <h1>Convert Reinin Coins to Cash</h1>
              <div className="recycling_history_item">Conversion<span className="recycling_history_item_span">100 Reinin Coins = $1</span></div>
              <div className="recycling_history_item">Cash sent to<span className="recycling_history_item_span">Reinin cash wallet</span></div>
              <h1>You will receive<span>${this.state.converCoinsOption[this.state.convertCoinsSelect]}</span></h1>
              <div className="btn_edit_profil" onClick={() => this.convertPointsToCash()}>Convert Point</div>
            </div>
          }
          {this.state.content == "convert_success" &&
            <div className="save_profil_success convert_success_container">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.92 17.92 0 40 0C62.08 0 80 17.92 80 40C80 62.08 62.08 80 40 80C17.92 80 0 62.08 0 40ZM14.7994 42.8002L29.1594 57.1602C30.7194 58.7202 33.2794 58.7202 34.7994 57.1602L65.1594 26.8002C66.7194 25.2402 66.7194 22.7202 65.1594 21.1602C63.5994 19.6002 61.0794 19.6002 59.5194 21.1602L31.9994 48.6802L20.4394 37.1602C18.8794 35.6002 16.3594 35.6002 14.7994 37.1602C14.0504 37.9075 13.6295 38.9221 13.6295 39.9802C13.6295 41.0382 14.0504 42.0528 14.7994 42.8002Z" fill="#1AA260" />
              </svg>
              <h1>Conversion successful</h1>
              <div className="withdraw_check_faq">
                <h1>Want to withdraw cash?</h1>
                <p>Go to your Cash balance tab in order to initiate a cash withdrawal</p>
                <div className="withdraw_check_faq_btn" onClick={() => this.setState({ content: "cash_balance", returnSection: "list" })}>View cash balance</div>
              </div>
            </div>
          }
          {this.state.content == "my_rewards" &&
            <div className="rewards_redeemed_container rewards_redeemed_container_profile">
              <h1>Rewards redeemed</h1>
              {this.state.redeemedChallenges.length ?
                <div className="cards-list-container no-padding cards-list-two-info cards-list-rewards">
                  <CardsList
                    noContainer
                    cards={
                      this.state.redeemedChallenges
                        .map(challenge =>
                          ["challenge_reward", challenge.reward, challenge.title, "/", challenge.brand.replace(/[^\w\s]/gi, '').replace(/\s/g, '').toLowerCase()]
                        )
                    }
                  />
                </div> : ""}
            </div>
          }

        </div>
      )
    } else {
      return (<></>)
    }

  }
}

// Connect redux to component
const mapStateToProps = state => ({
  userToken: state.user.userToken,
})
export default Form.create({ name: "edit_transfer" })(connect(mapStateToProps)(AccountLayout))