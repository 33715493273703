// Import dependencies
import React from "react"
import Download from "../../assets/icons/download.svg"

class LegalTab extends React.Component {
  render() {
    return(
      <div className="legal-section_container">
        <div className="legal-section-card legal-section-header">
          <span className="legal-section-date">LAST UPDATE</span>
          <div className="download-icon-container">DOWNLOAD</div>
        </div>
        <div className="legal-section-card">
          <h1>Membership agreement</h1>
          <span className="legal-section-date">upload date</span>
          <div className="download-icon-container"><Download /></div>
        </div>
        <div className="legal-section-card">
          <h1>Terms of service</h1>
          <span className="legal-section-date">upload date</span>
          <div className="download-icon-container"><Download /></div>
        </div>
        <div className="legal-section-card">
          <h1>Privacy policy</h1>
          <span className="legal-section-date">upload date</span>
          <div className="download-icon-container"><Download /></div>
        </div>
      </div>
    );
  }
}

export default LegalTab
