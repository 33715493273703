// Import dependencies
import React from "react"

// Import components
import { ParagraphText } from "../../styled-components/UILibrary"

// Import styles
import "./account-layout.css"
import { Constants } from "../../constants/Constants"

/*
    Tab component for the Account page
*/
const AccountTabs = ({ currentTab, onChange, tabs, isMain, signOut }) => (
  <div className="account-layout__tabs">
    {isMain ? <div className="account-layout__tabs-shadow" /> : null}

    <div className="account-layout__tabs-container">
      {tabs.map(item => (
        <div
          className="account-layout__tab"
          key={item.id}
          onClick={() => onChange(item.id)}
        >
          <ParagraphText
            colour={
              currentTab === item.id
                ? isMain
                  ? Constants.mainGreen
                  : Constants.titleColour
                : "#C3CDD5"
            }
            style={currentTab === item.id
              ? isMain
                ? {fontWeight: 600}
                : {fontWeight: 500}
              : {fontWeight: 500}}
            separator
            link
          >
            {item.tab}
          </ParagraphText>
        </div>
      ))}

      <div className="account-layout__tab" style={{margin: "0 0 0 auto"}}>
        <div className="account-layout__log-out" onClick={() => signOut()}>
          <ParagraphText colour={Constants.mainRed} separator>
            Log out
          </ParagraphText>
        </div>
      </div>

    </div>
    <div className="bottom_border_tabs" />
  </div>
)

export default AccountTabs
