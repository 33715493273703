// Import dependencies
import React from "react"
import { navigate } from "gatsby"

// Import components
import InputField from "../form/input/inputField"
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import GeneralButton from "../form/buttons/GeneralButton"
import CheckIcon from "../../assets/icons/check.svg";

// Import styles
import "../../pages/forgot-password.css"

// Import Firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

/*
    Forgot Password Component
*/
export default class SettingsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      error: null,
      success: false
    }
  }

  showSuccess = () => {
    this.setState({ success: true });
  }

  render() {

      return (
        <>
        <div className="change-password__content" style={this.state.success ? {display: "none"} : {}}>
          <TitleText className="title-change-password" subTitle center margin="margin-bottom: 40px">
            reset password
          </TitleText>
          <input name="block-autocomplete" style={{opacity: 0, width: 1, height: 1, position: "absolute"}} tabindex="-1" />

          <InputField placeholder="Old password" noForm typePassword/>

          <InputField placeholder="New password" noForm typePassword/>

          {this.state.error ? (
            <p
                style={{
                  fontFamily: "Inter, sans-serif !important",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  color: "#FF0C3E",
                  opacity: 0.6,
                  textAlign: "left",
                  marginLeft: "20px",
                  marginTop: "-20px"
                }}
            >
              {this.state.error}
            </p>
          ) : null}

          <div className="change-password__button" onClick={this.showSuccess}>
            <GeneralButton
              title="Save"
              type="primary"
              fit
               
              margin={{ marginTop: 15 }}
            />
          </div>
        </div>
        {this.state.success &&
          <div className="container_statu" style={{paddingTop: 60}}>
            <CheckIcon/>
            <h1>Password updated</h1>
          </div>
        }
        </>
      )
  }
}
