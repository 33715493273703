// Import dependencies
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Tabs } from "antd"

// Import components
import LoginComponent from "../account/login"
import RegisterComponent from "../account/register"
import Layout from "../../layouts/layout"
import SEO from "../seo/seo"
import Logo from "../../assets/icons/logo_green_login.svg";


// Import styles
import "../../pages/account.css"

const { TabPane } = Tabs

/*
    Login/Register page
*/
const LoginRegisterLayout = ({ redirect, stepRedirect, recycleRedirect, logItem }) => {
  // GraphQL image query
  const images = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout noWhatsapp noHeader loginFooter>
      <SEO title="Account" description="Login to your account." />
      <div className="account_container">
        <Link to="/" className="account_logo" >
          <Logo />
        </Link>
        <LoginComponent redirect={redirect} stepRdirect={stepRedirect} recycleRedirect={recycleRedirect} logItem={logItem} />
      </div>
    </Layout>
  )
}

export default LoginRegisterLayout
