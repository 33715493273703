// Import dependencies
import React from "react"
import Download from "../../assets/icons/download.svg"

import "./tab-section.css";

import {getFirebase} from "../../api/firebase";

class AnalyticsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      analyticsData: [],
      dataLoad: false,
      mostBrand: "",
    }
  }
  componentDidMount() {
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("orders")
          .where("account_info.email", "==", this.props.accountInfo.email)
          .get()
          .then(querySnapshot => {
            let dataArray = [];
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              dataArray.push(doc.data())
            });
            this.setState({analyticsData: dataArray, dataLoad: true})

            let brandsArray = [];
            this.state.analyticsData
            .map(product => product.items)
            .forEach(item => {item.forEach(subItem => {brandsArray.push(subItem.brand)})});
            brandsArray = brandsArray.filter(el => el != null);
            let mostRecycledBrand = brandsArray.sort((a,b) =>
                  brandsArray.filter(v => v===a).length
                - brandsArray.filter(v => v===b).length
            ).pop();
            this.setState({mostBrand: mostRecycledBrand});

          })
      });


  }

  render() {
    return(
      <div className="cards-list cards-list-flex">
        <h1>Your recycling stats</h1>
        <div className="cards-list-item">
          <div className="analytics-flex">
            <div className="statics-container">
              <div className="statics-item">
                  <span className="statics-title">MEMBER SINCE</span>
                  <span className="statics-value">{this.props.accountInfo && this.props.accountInfo["sign_date"] ? this.props.accountInfo["sign_date"] : "-"}</span>
              </div>
              <div className="statics-item">
                  <span className="statics-title">LAST RECYCLED</span>
                  <span className="statics-value">{this.state.analyticsData && this.state.analyticsData.length && this.state.dataLoad ? this.state.analyticsData.sort((a, b) => a.dateCode - b.dateCode)[0]["date"] : "-"}</span>
              </div>
              <div className="statics-item">
                  <span className="statics-title">TIMES RECYCLED</span>
                  <span className="statics-value">{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.length : 0}</span>
              </div>
            </div>

            <div className="statics-container">
              <div className="statics-item">
                <span className="statics-title">RECYCLING FREQUENCY</span>
                <span className="statics-value">{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.length : 0}x/month</span>
              </div>
              <div className="statics-item">
                <span className="statics-title">TOTAL EARNED (APPROX.)</span>
                <span className="statics-value">${this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (Math.round(b.total || 0) * 100) / 100, 0) : 0}</span>
              </div>
              <div className="statics-item">
                <span className="statics-title">EARNINGS DONATED</span>
                <span className="statics-value">{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (Math.round(b.donation || 0) * 100) / 100, 0) : 0}</span>
              </div>
            </div>

            <div className="statics-container">
              <div className="statics-item">
                <span className="statics-title">TOTAL RECYCLED (KGs)</span>
                <span className="statics-value">{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (b.totalWeight || 0), 0) : 0} KG</span>
              </div>
              <div className="statics-item">
                <span className="statics-title">TOTAL RECYCLED (ITEMS)</span>
                <span className="statics-value">{this.state.analyticsData && this.state.dataLoad ? this.state.analyticsData.reduce((a, b) => a + (b.items.length || 0), 0) : 0}</span>
              </div>
              <div className="statics-item">
                <span className="statics-title">MOST RECYCLED BRAND</span>
                <span className="statics-value">{this.state.mostBrand ? this.state.mostBrand : "-"}</span>
              </div>
            </div>

          </div>
        </div>
        <h1>Premium analytics</h1>
        <p>Coming soon</p>
      </div>
    );
  }
}

export default AnalyticsTab
