// Import dependencies
import React from "react"
import { Row, Col, Checkbox } from "antd"

// Import components
import TitleSection from "../sections/titleSection"
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"
import { ParagraphText } from "../../styled-components/UILibrary"

// Import styles
import "./account-layout.css"
import "../../pages/step.css"

// Import firebase
import { getFirebase } from '../../api/firebase'

/*
    Billing tab from the Settings tab in the Account page
*/
export default class AccountSettingsBilling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apt_suite_etc: this.props.data.apt_suite_etc,
      city: this.props.data.city,
      country: this.props.data.country,
      home_address: this.props.data.home_address,
      postal_code: this.props.data.postal_code,
    }
    this.updateInput = this.updateInput.bind(this)
  }

  updateInput = (input, e) => {
    this.setState({ [input]: e.target.value })
  }

  updateAccountInfo = () => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.uid)
        .update({
          "billing_info.apt_suite_etc": this.state.apt_suite_etc,
          "billing_info.city": this.state.city,
          "billing_info.country": this.state.country,
          "billing_info.home_address": this.state.home_address,
          "billing_info.postal_code": this.state.postal_code,
        })
        .then( /* SUCCESS */ )
        .catch( /* HANDLE ERROR */ )
    })
  }

  render() {
    return (
      <>
        <div style={{ height: 55, width: "100%" }} />

        <TitleSection
          title="Billing information"
        />

        <Row
          gutter={this.props.currentWidth > 767 ? 30 : 0}
          className="step__double-row"
          style={{ marginTop: 30 }}
          type="flex"
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Home address"
              noForm
              defaultValue={this.props.data.home_address}
              allowClear
              onChange={e => this.updateInput("home_address", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Apt, suite, etc"
              noForm
              defaultValue={this.props.data.apt_suite_etc}
              allowClear
              onChange={e => this.updateInput("apt_suite_etc", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Country"
              noForm
              defaultValue={this.props.data.country}
              allowClear
              onChange={e => this.updateInput("country", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="City"
              noForm
              defaultValue={this.props.data.city}
              allowClear
              onChange={e => this.updateInput("city", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Postal code"
              noForm
              defaultValue={this.props.data.postal_code}
              allowClear
              onChange={e => this.updateInput("postal_code", e)}
            />
          </Col>
        </Row>

        {this.props.contact ? (
          <div style={{ width: "100%", display: "flex", maxWidth: 770 }}>
            <Checkbox
            // onChange={e => onChange(e.target.checked)}
            >
              Use this for billing
            </Checkbox>
          </div>
        ) : null}

        <GeneralButton
          title="UPDATE"
          type="primary"
          margin={{ marginTop: 15 }}
          onClick={this.updateAccountInfo}
        />

        <div className="account-layout__footer">
          <ParagraphText fontSize={12} center>
            Copyright © 2018 Reinin.
            {/*<br />*/}
            {/*by Dmitry Poliyivets*/}
          </ParagraphText>
        </div>
      </>
    )
  }
}
