// Import dependencies
import React from "react";
import { Row, Col, Skeleton, Empty } from "antd";

// Import components
import TitleSection from "../sections/titleSection";
import PremiumOrderCard from "./premiumOrderCard";
import GeneralOrderCard from "./generalOrderCard";
import { ParagraphText, BoxComponent } from "../../styled-components/UILibrary";

// Import styles
import "./account-layout.css";
import "../../pages/step.css";

/*
    Order Layout for Premium and General tabs from the Account page
*/
const OrderLayout = ({ currentWidth, isPremium, data, isLoading }) => (
  <>
    <TitleSection title="Order history" />

    <Row
      gutter={currentWidth > 767 ? 30 : 0}
      className="step__double-row"
      style={{ maxWidth: 900 }}
      type="flex"
    >
      {isLoading ? (
        <>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: 30 }}
          >
            <BoxComponent
              padding="20px 30px"
              radius={13}
              style={{ height: "100%" }}
            >
              <Skeleton active paragraph={{ rows: 4 }} />
            </BoxComponent>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: 30 }}
          >
            <BoxComponent padding="20px 30px" radius={13}>
              <Skeleton
                active
                paragraph={{ rows: 4 }}
                style={{ height: "100%" }}
              />
            </BoxComponent>
          </Col>
        </>
      ) : data.length === 0 ? (
        <div className="account-layout__empty-container">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No orders" />
        </div>
      ) : (
        data.map((item, i) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: 30 }}
              key={i}
            >
              {isPremium ? (
                <PremiumOrderCard
                  item={item.item}
                  condition={item.condition}
                  powersOn={item.powersOn}
                  value={"$" + item.quote}
                  date={item.date}
                />
              ) : (
                <GeneralOrderCard
                  item={item.item}
                  quantity={item.quantity}
                  quote={"$" + item.quote}
                  totalPrice={"$" + item.total_price}
                  date={item.date}
                />
              )}
            </Col>
          );
        })
      )}
    </Row>

    <div className="account-layout__footer">
      <ParagraphText fontSize={12} center>
        Copyright © 2018 Reinin.
        {/*<br />*/}
        {/*by Dmitry Poliyivets*/}
      </ParagraphText>
    </div>
  </>
);

export default OrderLayout;
