// Import dependencies
import React from "react"
import { navigate } from "gatsby"

// Import components
import InputField from "../form/input/inputField"
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import GeneralButton from "../form/buttons/GeneralButton"
import CheckIcon from "../../assets/icons/check.svg";

// Import styles
import "../../pages/forgot-password.css"

// Import Firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

/*
    Forgot Password Component
*/
class ProfilTabPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      error: null,
      home_address: this.props.homeAdress ? this.props.homeAdress.trim() : "",
      postal_code: this.props.postalCode ? this.props.postalCode.trim() : "",
      contact_number: this.props.contactNumber ?  this.props.contactNumber.trim() : "",
    }
    this.updateHomeAddress = this.updateHomeAddress.bind(this);
    this.updatePostalCode = this.updatePostalCode.bind(this);
    this.updateContactNumber = this.updateContactNumber.bind(this);
  }

  updateHomeAddress = value => {
    this.setState({ home_address: value });
  };

  updatePostalCode = value => {
    this.setState({ postal_code: value });
  };

  updateContactNumber = value => {
    this.setState({ contact_number: value });
  };

  render() {

      return (
        <div className="popup_container"  style={this.props.popupOpen ? {} : {display: "none"}}>
        <div className="popup">
        <div className="edit-profil__content" style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
          <TitleText className="title-edit-profil" subTitle center margin="margin-bottom: 40px" style={this.props.success ? {visibility: "hidden"} : {}}>
            Edit details
          </TitleText>
          <div className="category-drawer__container-close-button" onClick={this.props.togglePopup}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L8.99996 7.11996L2.47996 0.599961C1.95996 0.079961 1.11996 0.079961 0.599961 0.599961C0.079961 1.11996 0.079961 1.95996 0.599961 2.47996L7.11996 8.99996L0.599961 15.52C0.079961 16.04 0.079961 16.88 0.599961 17.4C1.11996 17.92 1.95996 17.92 2.47996 17.4L8.99996 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9066 1.97329 17.9066 1.11996 17.4 0.613295V0.613295Z" fill="#828282"/>
          </svg>
          </div>

          {this.props.success &&
            <div className="container_statu" style={{position: "absolute", width: "100%", left: 0}}>
              <CheckIcon/>
              <h1 style={{marginTop: 15}}>You’re up to date</h1>
            </div>
          }

          <div style={this.props.success ? {visibility: "hidden"} : {}}>

            <InputField placeholder="Street / Blk. / Unit No." noForm value={this.state.home_address} onChange={(e) => {this.updateHomeAddress(e.target.value)}}/>

            <InputField placeholder="Postal Code" noForm  value={this.state.postal_code} numeric onChange={(e) => {this.updatePostalCode(e.target.value)}}/>
 
            <InputField placeholder="Email" noForm value={this.props.email} disabled id="edit_profil_disabled"/>

            <InputField placeholder="Contact Number" noForm value={this.state.contact_number} phoneNumber onChange={(e) => {this.updateContactNumber(e.target.value)}}/>

            {this.state.error ? (
              <p
                  style={{
                    fontFamily: "Inter, sans-serif !important",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    color: "#FF0C3E",
                    opacity: 0.6,
                    textAlign: "left",
                    marginLeft: "20px",
                    marginTop: "-20px"
                  }}
              >
                {this.state.error}
              </p>
            ) : null}

              <div className="edit-profil__button" onClick={() => {this.props.editProfil(
                {
                  home_address: this.state.home_address,
                  postal_code: this.state.postal_code,
                  contact_number: this.state.contact_number,
                }
              )}}  style={this.props.success ? {visibility: "hidden"} : {}}>
                <GeneralButton
                  title="Save"
                  type="primary"
                  fit

                  margin={{ marginTop: 15 }}
                />
              </div>
            </div>

        </div>
        </div>
        </div>
      )
  }
}

export default ProfilTabPopup
