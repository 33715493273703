// Import dependencies
import React from "react"

// Import components
import Layout from "../layouts/layout"
import SEO from "../components/seo/seo"
import AccountLayout from "../components/account/accountLayout"
import LoginRegisterLayout from "../components/account/loginRegisterLayout"
import HeaderSeparator from "../components/separators/headerSeparator";
import SearchBar from "../components/search-bar/searchBar";
import { navigate } from "gatsby"
import { getFirebase } from "../api/firebase"
// Import styles
import "./account.css"

// Import from Redux
import { connect } from "react-redux"

/*
    Account page
*/
class Account extends React.Component {
  constructor(props) {
    super(props);
    this.isBrowser = this.isBrowser.bind(this);
  }
  isBrowser = () => typeof window !== "undefined";

  componentWillMount() {
    if (!this.props.loggedIn && this.isBrowser()) {
      navigate("/login");
    }
    const { location, userToken } = this.props
    const params = new URLSearchParams(location.search);
    const membershipKey = params.get("key");
    const membershipUsertoken = params.get("usertoken");

    if (membershipKey && membershipUsertoken) {
      // get user membership code
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");
      Promise.all([app, firestore])
          .then(([firebase]) => {
              getFirebase(firebase)
              .firestore()
              .collection("users")
              .doc(membershipUsertoken)
              .get()
              .then(doc => {
                if (doc.exists) {

                  let membershipCodeYS = doc.data().membership_info.membership_code_ys ? doc.data().membership_info.membership_code_ys : null;
                  let membershipCodeYR = doc.data().membership_info.membership_code_yr ? doc.data().membership_info.membership_code_yr : null;
                  let membershipCodeQS = doc.data().membership_info.membership_code_qs ? doc.data().membership_info.membership_code_qs : null;
                  let membershipCodeQR = doc.data().membership_info.membership_code_qr ? doc.data().membership_info.membership_code_qr : null;
                  if (
                    (membershipCodeYS == membershipKey || membershipCodeYR == membershipKey || membershipCodeQS == membershipKey || membershipCodeQR == membershipKey)
                    && userToken.uid == membershipUsertoken
                  ) {
                    // update the membership code and information
                    let membershipSME = membershipKey[1] == "S" ? true : false;
                    let membershipYearly = membershipKey[0] == "Y" ? true : false;
                    let date = new Date();
                    const dd = date.getDate();
                    const mm = date.getMonth();
                    const yyyy = date.getFullYear();
                    let hours = date.getHours();
                    let minutes = date.getMinutes();
                    hours = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? '0'+minutes : minutes;              

                    let mmEnd = mm;
                    let yyyyEnd = yyyy;
                    if (membershipYearly) {
                      yyyyEnd += 1;
                    } else {
                      mmEnd += 3;
                    }

                    getFirebase(firebase)
                    .firestore()
                    .collection("users")
                    .doc(membershipUsertoken)
                    .update({
                      "membership_info.end_date": yyyyEnd + " " + mmEnd + " " + dd + " " + hours + " " + minutes,
                      "membership_info.is_member": true,
                      "membership_info.is_SME": membershipSME,
                      "membership_info.membership_code_ys": "YS" + Math.floor(100000 + Math.random() * 900000),
                      "membership_info.membership_code_yr": "YR" + Math.floor(100000 + Math.random() * 900000),
                      "membership_info.membership_code_qs": "QS" + Math.floor(100000 + Math.random() * 900000),
                      "membership_info.membership_code_qr": "QR" + Math.floor(100000 + Math.random() * 900000),
                      "membership_info.membership_id": null,
                      "membership_info.membership_is_yearly": membershipYearly,
                      "membership_info.start_date": yyyy + " " + mm + " " + dd + " " + hours + " " + minutes,
                      "membership_info.stripe_customer_id": null,
                      "membership_info.stripe_subscription_id": null,
                    });
                  }
                }
              });
            }
          )
    }
  }
  render() {
    // Get data
    const { loggedIn, location } = this.props

    return (
      <Layout noWhatsapp noFooter>
        <SearchBar/>
        <SEO title="Account" description="Login to your account." />
        <SearchBar/>
        {loggedIn && <AccountLayout />}
      </Layout>
    )
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  loggedIn: state.user.isLoggedIn,
  userToken: state.user.userToken,
})
export default connect(mapStateToProps)(Account)
