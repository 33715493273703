// Import dependencies
import React from "react"
import { Row, Col, Spin } from "antd"
import moment from "moment"

// Import components
import TitleSection from "../sections/titleSection"
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"
import { ParagraphText, TitleText } from "../../styled-components/UILibrary"

// Import styles
import "./account-layout.css"
import "../../pages/step.css"

// Import Firebase
import { getFirebase } from "../../api/firebase"
import { Constants } from "../../constants/Constants"

/*
    Account tab from the Settings tab in the Account page
*/
export default class AccountSettingsAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: this.props.data.first_name,
      last_name: this.props.data.last_name,
      email: this.props.data.email,
      phone: this.props.data.phone,
      message: null,
      loading: false,
    }
    this.updateInput = this.updateInput.bind(this)
    this.cancelSubscription = this.cancelSubscription.bind(this)
    this.finaliseCancelation = this.finaliseCancelation.bind(this)
  }

  updateInput = (input, e) => {
    this.setState({ [input]: e.target.value })
  }

  updateAccountInfo = () => {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.uid)
        .update({
          "account_info.first_name": this.state.first_name,
          "account_info.last_name": this.state.last_name,
          "account_info.email": this.state.email,
          "account_info.phone": this.state.phone,
        })
        .then(/* SUCCESS */)
        .catch(/* HANDLE ERROR */)
    })
  }

  finaliseCancelation() {
    const app = import("firebase/app")
    const firestore = import("firebase/firestore")

    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.uid)
        .update({
          "membership_info.is_member": false,
          "membership_info.start_date": null,
          "membership_info.stripe_customer_id": null,
          "membership_info.stripe_subscription_id": null,
          "membership_info.membership_id": null,
        })
        .then()
        .catch(/* HANDLE ERROR */)
    })
  }

  cancelSubscription = () => {
    this.setState({ loading: true })

    const app = import("firebase/app")
    const fuctions = import("firebase/functions")

    Promise.all([app, fuctions]).then(([firebase]) => {
      var revokeMembership = getFirebase(firebase)
        .functions()
        .httpsCallable("revokeMembership")
      var sendMemberCancelEmail = getFirebase(firebase)
        .functions()
        .httpsCallable("sendMemberCancelEmail")

      revokeMembership({
        uid: this.props.uid,
        subscription: this.props.membershipID,
      }).then(result => {
        if (result.data) {
          this.setState({
            message: "Successfully revoked your membership.",
            loading: false,
          })
          this.finaliseCancelation()
          sendMemberCancelEmail({
            email: this.props.data.email,
            name:
              this.props.data.first_name && this.props.data.last_name
                ? this.props.data.first_name + this.props.data.last_name
                : "No name specified",
            membership_number: this.props.membership.membership_id,
            date: this.props.membership.start_date,
            phone: this.props.data.phone,
          })
        } else {
          this.setState({
            message: "Error canceling membership, please contact support.",
            loading: false,
          })
        }
      })
    })
  }

  render() {
    return (
      <>
        <div style={{ height: 55, width: "100%" }} />

        <TitleSection title="Account information" />

        <Row
          gutter={this.props.currentWidth > 767 ? 30 : 0}
          className="step__double-row"
          style={{ marginTop: 30 }}
          type="flex"
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="First name"
              noForm
              defaultValue={this.props.data.first_name}
              allowClear
              onChange={e => this.updateInput("first_name", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Last name"
              noForm
              defaultValue={this.props.data.last_name}
              allowClear
              onChange={e => this.updateInput("last_name", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Email address"
              noForm
              defaultValue={this.props.data.email}
              allowClear
              onChange={e => this.updateInput("email", e)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputField
              placeholder="Primary phone"
              noForm
              defaultValue={this.props.data.phone}
              allowClear
              onChange={e => this.updateInput("phone", e)}
            />
          </Col>
        </Row>

        <GeneralButton
          title="UPDATE"
          type="primary"
          margin={{ marginTop: 15 }}
          onClick={this.updateAccountInfo}
        />

        {this.props.isMember &&
          (this.state.message ? (
            <ParagraphText
              colour={
                this.state.message.startsWith("Succ")
                  ? Constants.mainGreen
                  : Constants.mainRed
              }
              margin="margin-top: 40px"
            >
              {this.state.message}
            </ParagraphText>
          ) : (
            <>
              <div style={{ height: 70 }} />
              <TitleSection title="Your Membership" />

              <div
                style={{
                  backgroundColor: "#F6F8FA",
                  width: "100%",
                  borderRadius: 25,
                  padding: "30px 50px",
                  maxWidth: 550,
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {this.state.loading && (
                  <div className="account-layout__loading">
                    <Spin size="large" />
                  </div>
                )}

                <TitleText subTitle colour={Constants.mainGreen}>
                  Active
                </TitleText>
                <ParagraphText margin="margin-top: 15px">
                  {`Next payment is due on ${moment(
                    this.props.memberFrom,
                    "MMM Do, YYYY"
                  )
                    .add("years", 1)
                    .format("MMM Do, YYYY")}`}
                </ParagraphText>
                <ParagraphText margin="margin-top: 10px" fontSize={12}>
                  {`Last purchase ${this.props.memberFrom}`}
                </ParagraphText>

                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginTop: 20,
                  }}
                  onClick={this.cancelSubscription}
                >
                  <ParagraphText colour={Constants.mainRed}>
                    Cancel membership
                  </ParagraphText>
                </div>
              </div>
            </>
          ))}
      </>
    )
  }
}
