// Import dependencies
import React from "react";
import {Link} from "gatsby";

import "./tab-section.css";

class ProfilTab extends React.Component {
  render() {
    return(
      <>
      <div className="cards-list cards-list-flex">
        <div className="left-card-list">
          <div className="cards-list-item">
            <h1>PROFILE</h1>
            <span className="card-list-item-data">
              {this.props.accountInfo && this.props.accountInfo.first_name + " "}
              {this.props.accountInfo && this.props.accountInfo.last_name}
            </span>
            <span className="card-list-item-sub">Joined in {this.props.accountInfo && this.props.accountInfo["sign_date"] ? this.props.accountInfo["sign_date"].substr(-4) : "-"}</span>
          </div>
          <div className="cards-list-item">
            <h1>MEMBERSHIP PLAN</h1>
            <span className="card-list-item-data">
              {
                (this.props.membershipInfo && this.props.membershipInfo.membership_interval) ?
                this.props.membershipInfo.membership_interval == 3 ? "Quarterly" : "Yearly"
                : "-"
              }
            </span>
            <span className="card-list-item-sub" style={{marginTop: 25}}>MANAGE</span>
            <Link style={{marginTop: 10, display: "block"}}>Cancel membership</Link>
          </div>
        </div>
        <div className="right-card-list">
          <div className="cards-list-item cards-list-item-long">
            <h1>ADDRESS</h1>
            <span className="card-list-item-data">
              {(this.props.contactInfo && this.props.contactInfo.home_address) ? this.props.contactInfo.home_address : '-'}
            </span>
            <h1>POSTAL CODE</h1>
            <span className="card-list-item-data">
              {(this.props.contactInfo && this.props.contactInfo.postal_code) ? this.props.contactInfo.postal_code : '-'}
            </span>
            <h1>PHONE NUMBER</h1>
            <span className="card-list-item-data">
              {(this.props.accountInfo && this.props.accountInfo.phone) ? this.props.accountInfo.phone : '-'}
            </span>
            <h1>EMAIL</h1>
            <span className="card-list-item-data">
              {this.props.accountInfo ? this.props.accountInfo.email : '-'}
            </span>
            {this.props.accountInfo && <span className="edit_popup_btn" onClick={this.props.togglePopup}>EDIT</span>}
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default ProfilTab
